<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافة جديد"
          icon="pi pi-plus"
          v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('inventoryAdjustmentAdd')"
          @click="$router.push('inventoryAdjustment/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('inventoryAdjustmentDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :filters="filters"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه التسويه المخزني
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />

      <Column
        field="code"
        header="الكود"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="بحث بالكود"
          />
        </template>
      </Column>
      <Column
        field="date"
        header="التاريخ"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ $durationFormatFull(slotProps.data.date) }}
        </template>
        <template #filter>
          <InputText
            type="date"
            v-model="filters['date']"
            class="p-column-filter"
            placeholder="بحث بالتاريخ"
          />
        </template>
      </Column>

      <Column
        field="warehouseId.name"
        header="المستودع"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['warehouseId.name']"
            class="p-column-filter"
            placeholder="بحث بالمستودع"
          />
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <a
            :href="windowOrigin + '/admin/print/' + slotProps.data.id"
            target="_blank"
          >
            <Button
              icon="pi pi-download"
              class="p-button-help p-ml-2  p-button-rounded"
            />
          </a>

          <!-- <Button
            type="button"
            @click="
              $router.push('inventoryAdjustment/edit/' + slotProps.data.id)
            "
            icon="pi pi-pencil" v-tooltip="'تعديل'"
            v-if="$checkRoles('inventoryAdjustmentEdit')"
            class="p-button-success p-ml-2  p-button-rounded"
          ></Button> -->
          <Button
            type="button"
            icon="pi pi-trash"
            v-tooltip="'حذف'"
            v-if="$checkRoles('inventoryAdjustmentDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            v-if="slotProps.data.note"
            class="p-datatable-customers table table-striped"
            :value="[slotProps.data]"
          >
            <Column field="note" header="ملاحظات" />
          </DataTable>

          <DataTable
            :value="JSON.parse(slotProps.data.storeItemsList)"
            class="p-datatable-customers table table-striped"
            :filters="filters2"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column
              field="storeItemsId.code"
              header="الكود"
              filterMatchMode="contains"
              :sortable="true"
            >
              <template #filter>
                <InputText
                  v-model="filters2['storeItemsId.code']"
                  class="p-column-filter"
                  placeholder="بحث بالكود"
                />
              </template>
            </Column>

            <Column
              field="storeItemsId.name"
              header="المنتج"
              filterMatchMode="contains"
              :sortable="true"
            >
              <template #filter>
                <InputText
                  v-model="filters2['storeItemsId.name']"
                  class="p-column-filter"
                  placeholder="بحث بالمنتج"
                />
              </template>
            </Column>

            <Column field="quantity" header="الكميه">
              <template #body="slotProps2">
                {{ slotProps2.data.quantity.toFixed(2) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowOrigin: window.origin,
      list: [],
      selectedItems: [],

      expandedRows: [],
      filters: {},
      filters2: {},
      loading: true,
    };
  },
  methods: {
    getData() {
      this.$http
        .get(`inventoryAdjustment?inventoryType=inventory_adjustment`)
        .then(
          (response) => {
            this.loading = false;
            this.list = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`inventoryAdjustment/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`inventoryAdjustment/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    if (!this.$checkRoles('inventoryAdjustmentVeiw')) {
      this.$router.push('/admin/');
    }
    this.getData();
  },
};
</script>
